/*
 * Copyright 2024 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

import React, { useState } from "react";
import cx from "classnames";
import { Form } from "react-final-form";
import { ValidationErrors } from "final-form";

import {
  COUNTRY_TO_DIALING_CODE_MAP,
  COUNTRY_TO_STATE_MAP
} from "./stateAndCountryUtils";
import {
  SignupTelemetryDTO,
  UserInfoFromClearBitDTO,
  useSendSignupTelemetry
} from "services/ng";
import Spinner from "static/icons/spinner/Spinner";
import Field from "components/Field/Field";
import { useQueryParams } from "hooks/useQueryParams";
import {
  getGaClientID,
  getSavedRefererURL,
  redirectFromUserInfoPage
} from "utils/SignUpUtils";
import SecureStorage from "utils/SecureStorage";
import {
  allRichUserInfoFields,
  fieldNameLabelMapping,
  phoneNumberWithoutCountryCodeRegex
} from "./RichUserInfoUtils";
import css from "./RichUserInfo.module.css";
import SelectDropdownField from "components/Field/SelectDropdownField";

interface FormValues extends UserInfoFromClearBitDTO {
  dialingCode?: string;
}

interface RichUserInfoFormProps {
  initialValues: FormValues;
}

type DropdownOptionsType = Array<{ label: string; value: string }>;

const convertArrayToDropdownOptions = (
  arr: Array<string>
): DropdownOptionsType => {
  return arr.map((val) => {
    return { label: val, value: val };
  });
};

const COUNTRIES_WITH_STATES = Object.keys(COUNTRY_TO_STATE_MAP);
const COUNTRIES = Object.keys(COUNTRY_TO_DIALING_CODE_MAP);
const COUNTRY_OPTIONS = convertArrayToDropdownOptions(COUNTRIES);

enum STATE_TYPE {
  TEXT = "TEXT",
  SELECT_DROPDOWN = "SELECT_DROPDOWN"
}

export const RichUserInfoForm = (
  props: RichUserInfoFormProps
): React.ReactElement => {
  const { initialValues } = props;

  const {
    intent: intentFromQueryParams = "",
    signupAction,
    edition,
    utm_source,
    utm_content,
    utm_medium,
    utm_term,
    utm_campaign
  } = useQueryParams<{
    intent?: string;
    module?: string;
    signupAction?: string;
    edition?: string;
    billingFrequency?: string;
    utm_source?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_term?: string;
    utm_campaign?: string;
    marketPlaceToken?: string;
    inviteId?: string;
  }>();

  const refererURL = getSavedRefererURL();
  const gaClientIdFromCookie = getGaClientID();

  const [stateType, setStateType] = useState(
    COUNTRIES_WITH_STATES.includes(initialValues?.country as string)
      ? STATE_TYPE.SELECT_DROPDOWN
      : STATE_TYPE.TEXT
  );

  const {
    mutate: sendSignupTelemetry,
    loading: sendingSignupTelemetry
  } = useSendSignupTelemetry({});

  const onSubmit = async (values: FormValues) => {
    const intent = localStorage.getItem("intent") || intentFromQueryParams;
    const referer = localStorage.getItem("referer") || refererURL;
    const gaClientId =
      localStorage.getItem("gaClientId") || gaClientIdFromCookie;
    const visitorToken = localStorage.getItem("visitorToken") || "";
    const utmSource = localStorage.getItem("utm_source") || utm_source;
    const utmContent = localStorage.getItem("utm_content") || utm_content;
    const utmMedium = localStorage.getItem("utm_medium") || utm_medium;
    const utmTerm = localStorage.getItem("utm_term") || utm_term;
    const utmCampaign = localStorage.getItem("utm_campaign") || utm_campaign;
    const signedInEmail = (SecureStorage.getItem("email") || "") as string;
    const accountId = SecureStorage.getItem("acctId") as string;
    const accountName = SecureStorage.getItem("accountName") as string;
    const userId = SecureStorage.getItem("uuid") as string;

    const requestData: SignupTelemetryDTO & { dialingCode?: string } = {
      ...values,
      phoneNumber: (values?.dialingCode || "") + values.phoneNumber,
      accountId,
      accountName,
      userId,
      email: signedInEmail,
      intent,
      referer,
      gaClientId,
      visitorToken,
      utmInfo: {
        utmSource,
        utmContent,
        utmMedium,
        utmTerm,
        utmCampaign
      },
      userName: signedInEmail,
      signupAction,
      edition
    };

    delete requestData?.dialingCode; // dialingCode is concatenated with phoneNumber, hence not needed in payload
    try {
      await sendSignupTelemetry(requestData);
    } catch (err) {
      // do nothing
    }
    const baseUrl = window.location.pathname.replace("auth/", "");
    redirectFromUserInfoPage(baseUrl, intent);
  };

  const onValidation = (values: FormValues): ValidationErrors => {
    const errors: ValidationErrors = {};

    allRichUserInfoFields.forEach((fieldName) => {
      if (fieldName === "state" && stateType === STATE_TYPE.TEXT) {
        // state field is optional when it's of STATE_TYPE.TEXT type
        return;
      }
      if (!values[fieldName]) {
        errors[fieldName] = `${fieldNameLabelMapping[fieldName]} is required`;
      }
      if (fieldName === "phoneNumber" && !errors[fieldName]) {
        if (
          !phoneNumberWithoutCountryCodeRegex.test(
            values[fieldName]?.toString() as string
          )
        ) {
          errors[
            fieldName
          ] = `Enter ${fieldNameLabelMapping[fieldName]} with at least 6 digits and without dialing code. Example: 404 404 404`;
        }
      }
    });

    return errors;
  };

  const initialDialingCode = initialValues?.country
    ? COUNTRY_TO_DIALING_CODE_MAP[initialValues?.country]
    : undefined;

  return (
    <div className={css.richUserInfoFormContainer}>
      {sendingSignupTelemetry ? (
        <div className={css.center}>
          <Spinner />
        </div>
      ) : (
        <>
          <div className={css.convinceTextContainer}>
            <div>
              We need a few more details to get you right into your Harness
              account. This information will
            </div>
            <div>
              help us serve you better in your journey of using Harness.
            </div>
          </div>
          <Form
            initialValues={{
              ...initialValues,
              dialingCode: initialDialingCode
            }}
            onSubmit={onSubmit}
            validate={onValidation}
            render={({ handleSubmit, values, form }) => {
              let STATE_OPTIONS_BY_COUNTRY: DropdownOptionsType = [];
              const currentCountry = values["country"];
              if (
                currentCountry &&
                COUNTRIES_WITH_STATES.includes(currentCountry)
              ) {
                const states = COUNTRY_TO_STATE_MAP[currentCountry];
                STATE_OPTIONS_BY_COUNTRY = convertArrayToDropdownOptions(
                  states
                );
              }
              return (
                <form
                  className={cx(
                    "layout-vertical spacing-medium ",
                    css.credsFormInput
                  )}
                  onSubmit={handleSubmit}
                >
                  {!initialValues["companyName"] && (
                    <div>
                      <Field
                        name="companyName"
                        label="Company Name"
                        placeholder="Harness"
                        aria-labelledby="Company Name"
                      />
                    </div>
                  )}

                  {!initialValues["firstName"] && (
                    <div>
                      <Field
                        name="firstName"
                        label="First Name"
                        placeholder="John"
                      />
                    </div>
                  )}

                  {!initialValues["lastName"] && (
                    <div>
                      <Field
                        name="lastName"
                        label="Last Name"
                        placeholder="Doe"
                      />
                    </div>
                  )}

                  {!initialValues["jobTitle"] && (
                    <div>
                      <Field
                        name="jobTitle"
                        label="Job Title"
                        placeholder="Software Developer"
                      />
                    </div>
                  )}

                  {!initialValues["country"] && (
                    <div>
                      <SelectDropdownField
                        id="country"
                        label="Country"
                        name="country"
                        options={COUNTRY_OPTIONS}
                        onChange={(countryName) => {
                          const dialingCode =
                            countryName &&
                            COUNTRY_TO_DIALING_CODE_MAP[countryName];
                          if (dialingCode) {
                            form.change("dialingCode", dialingCode);
                            if (COUNTRIES_WITH_STATES.includes(countryName)) {
                              setStateType(STATE_TYPE.SELECT_DROPDOWN);
                              form.change("state", undefined);
                            } else {
                              setStateType(STATE_TYPE.TEXT);
                              form.change("state", undefined);
                            }
                          }
                        }}
                      />
                    </div>
                  )}

                  {!initialValues["state"] && (
                    <div>
                      {stateType === STATE_TYPE.SELECT_DROPDOWN ? (
                        <SelectDropdownField
                          id="state"
                          label="State"
                          name="state"
                          options={STATE_OPTIONS_BY_COUNTRY}
                        />
                      ) : (
                        <Field
                          name="state"
                          label="State (optional)"
                          placeholder="Enter State"
                        />
                      )}
                    </div>
                  )}

                  {!initialValues["phoneNumber"] && (
                    <div className={css.phoneNumberWrapper}>
                      <Field
                        className={css.dialingCode}
                        name="dialingCode"
                        label="Dialing Code"
                        disabled
                      />
                      <Field
                        className={css.phoneNumber}
                        name="phoneNumber"
                        label="Phone Number"
                        placeholder="Example: 628 123 4567"
                      />
                    </div>
                  )}

                  <button type="submit" className="button primary">
                    Enter your Harness Account
                  </button>
                </form>
              );
            }}
          />
        </>
      )}
    </div>
  );
};
