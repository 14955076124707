/*
 * Copyright 2024 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useUserInfo } from "services/ng";
import RouteDefinitions from "RouteDefinitions";
import Spinner from "static/icons/spinner/Spinner";
import BasicLayoutExperimental from "pages/SignUp/SaasExperimentalForms/BasicLayout/BasicLayoutExperimental";
import { useQueryParams } from "hooks/useQueryParams";
import { redirectFromUserInfoPage } from "utils/SignUpUtils";
import { EMAIL_VERIFY_STATUS } from "utils/StringUtils";
import SecureStorage from "utils/SecureStorage";
import { RichUserInfoForm } from "./RichUserInfoForm";
import { allRichUserInfoFields } from "./RichUserInfoUtils";
import css from "./RichUserInfo.module.css";

export const RichUserInfo = (): React.ReactElement => {
  const { intent = "" } = useQueryParams<{
    intent?: string;
  }>();
  const history = useHistory();

  const signedInEmail = SecureStorage.getItem("email") as string;
  const token = SecureStorage.getItem("token") || "";

  const {
    data: richUserInfo,
    loading: fetchingRichUserInfo,
    error
  } = useUserInfo({
    queryParams: {
      email: signedInEmail || ""
    },
    requestOptions: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    lazy: !signedInEmail
  });

  useEffect(() => {
    const baseUrl = window.location.pathname.replace("auth/", "");
    if (!fetchingRichUserInfo) {
      if (richUserInfo?.data) {
        const userInfoFieldsNotPresent = allRichUserInfoFields.filter(
          (fieldName) => !richUserInfo?.data?.[fieldName]
        );

        if (!userInfoFieldsNotPresent.length) {
          redirectFromUserInfoPage(baseUrl, intent);
        }
      } else {
        // code path for when "signedInEmail" is falsy: Redirect to sign-in page
        history.push({
          pathname: RouteDefinitions.toSignIn()
        });
      }
    }
  }, [richUserInfo, fetchingRichUserInfo]);

  useEffect(() => {
    if (error && error?.status === 401) {
      window.location.href = `/auth/#/signup`;
    }
  }, [error]);

  return (
    <>
      {fetchingRichUserInfo ? (
        <div className={css.center}>
          <Spinner />
        </div>
      ) : (
        <BasicLayoutExperimental>
          <div className={css.richUserInfoContainer}>
            <div className={css.title}>{EMAIL_VERIFY_STATUS.SUCCESS}</div>
            <RichUserInfoForm initialValues={richUserInfo?.data || {}} />
          </div>
        </BasicLayoutExperimental>
      )}
    </>
  );
};
